<template>
  <div style="height: 100vh; width: 100vw;">
    <div @click="$router.push('/')" style="padding: 20px 0 0 20px;font-weight: bolder; color: white;font-size: 20px; cursor: pointer;">
      Mortgage Envy
    </div>
    <div
      v-if="loaded"
      class="d-flex justify-content-center align-items-center full-height"
    >
      <div
        class="col-5 align-self-center"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item
            :title="questions.questionOne.question"
          >
            <b-list-group flush>
              <hwa-list-item
                v-for="(ans,index) in questions.questionOne.answers"
                :key="index"
                class="animate__animated animate__fadeIn"
                :title="ans.title"
                :selected="ans.selected"
                @click.native="onSelect(1,index)"
              />
            </b-list-group>
          </app-collapse-item>
          <div
            class="mt-2 "
          />
          <app-collapse-item
            :title="questions.questionTwo.question"
          >
            <b-list-group flush>
              <template v-for="(ans,index) in questions.questionTwo.answers">
                <hwa-list-item
                  v-if="!(selectedQ1Answer===1 && index===questions.questionTwo.answers.length-1)"
                  :key="index"
                  class="animate__animated animate__fadeIn"
                  :title="ans.title"
                  :selected="ans.selected"
                  @click.native="onSelect(2,index)"
                />
              </template>

            </b-list-group>
          </app-collapse-item>
          <div class="mt-2" />
          <app-collapse-item
            v-if="selectedQ2Answer===2"
            :title="questions.questionThree.question"
          >
            <b-list-group flush>
              <hwa-list-item
                v-for="(ans,index) in questions.questionThree.answers"
                :key="index"
                class="animate__animated animate__fadeIn"
                :title="ans.title"
                :selected="ans.selected"
                @click.native="onSelect(3,index)"
              />
            </b-list-group>
          </app-collapse-item>
          <div class="mt-2" />
          <app-collapse-item
            v-if="selectedQ2Answer===2"
            :title="questions.questionFour.question"
          >
            <hwa-input
              v-model="questions.questionFour.userProvidedAns.amount"
              placeholder="Amount"
            />
            <small>If there's an existing tenant, enter the rent amount from their lease. If you're bringing in new tenants,
              enter the expected rent.</small>
          </app-collapse-item>
        </app-collapse>
        <div class="mt-2" />

        <div class="d-flex">
          <hwa-button
                  icon="ArrowLeftCircleIcon"
                  label="PREVIOUS"
                  @onClick="$router.back()"
          />
          <div class="ml-1" />
          <hwa-button
                  :disabled="!valid"
                  label="NEXT"
                  :loading="nextLoading"
                  @onClick="onNext()"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center full-height"
    >
      <grid-loader
        :loading="true"
        color="white"
      />

    </div>
  </div>
</template>

<script>
import { BListGroup } from 'bootstrap-vue'
import HwaListItem from '@/layouts/components/HwaListItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default {
  name: 'StartPurchase2',
  components: {
    HwaButton,
    HwaInput,
    HwaListItem,
    BListGroup,
    AppCollapse,
    AppCollapseItem,
    GridLoader,
  },
  data() {
    return {
      loaded: false,
      selectedQ2Answer: null,
      selectedQ1Answer: null,
      nextLoading: false,
      questions: {
        questionOne: {
          id: null,
          question: '',
          answers: [],
          userProvidedAns: {},
        },
        questionTwo: {
          id: null,
          question: '',
          answers: [
          ],
          userProvidedAns: {},
        },
        questionThree: {
          id: null,
          question: '',
          answers: [
          ],
          userProvidedAns: {},
        },
        questionFour: {
          id: null,
          question: '',
          answers: [],
          userProvidedAns: {
            amount: null,
          },
        },
      },
    }
  },
  computed: {
    valid() {
      if (this.selectedQ2Answer !== 2) {
        return this.questions.questionOne.answers.some(q => q.selected)
                && this.questions.questionTwo.answers.some(q => q.selected)
      }
      return this.questions.questionOne.answers.some(q => q.selected)
          && this.questions.questionTwo.answers.some(q => q.selected)
          && this.questions.questionThree.answers.some(q => q.selected)
          && !!this.questions.questionFour.userProvidedAns.amount
    },
  },
  watch: {
    selectedQ2Answer(ans) {
      if (ans !== 2) {
        this.questions.questionThree.answers = this.questions.questionThree.answers.map(q => ({ ...q, selected: false }))
        this.questions.questionFour.userProvidedAns.amount = null
      }
    },
  },
  mounted() {
    this.getStepQuestions()
  },
  methods: {
    onSelect(questionNo, index) {
      // if q1, set the answered question to determine which other questions to show
      if (questionNo === 1) {
        this.selectedQ1Answer = index
        if (index === 1) {
          this.questions.questionTwo.answers = this.questions.questionTwo.answers.map((a, i) => {
            if (a.selected) {
              if (i === 2) {
                this.selectedQ2Answer = null
                return { ...a, selected: false }
              }
            }
            return a
          })
        }
      }
      // same for q2
      if (questionNo === 2) {
        this.selectedQ2Answer = index
      }
      switch (questionNo) {
        case 1:
          this.questions.questionOne.answers = this.questions.questionOne.answers.map(q => ({ ...q, selected: false }))
          this.questions.questionOne.answers[index].selected = !this.questions.questionOne.answers[index].selected
          break
        case 2:
          this.questions.questionTwo.answers = this.questions.questionTwo.answers.map(q => ({ ...q, selected: false }))
          this.questions.questionTwo.answers[index].selected = !this.questions.questionTwo.answers[index].selected
          break
        case 3:
          this.questions.questionThree.answers = this.questions.questionThree.answers.map(q => ({ ...q, selected: false }))
          this.questions.questionThree.answers[index].selected = !this.questions.questionThree.answers[index].selected
          break
      }
    },
    onNext() {
      const id = localStorage.getItem('anonymous')
      this.nextLoading = true
      this.$http.post(`api/questions/purchase/2/${id}?application_id=${this.$store.state.auth.applicationId}`, this.questions).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: 'Second step done, lets move on.',
            variant: 'success',
          },
        })
        this.$store.state.auth.stepTwoCompleted = true
        this.nextLoading = false
        this.$router.push({ name: 'step-3' })
      }).catch(err => {
        this.nextLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'CheckCircleIcon',
            text: 'Something went wrong, try again.',
            variant: 'danger',
          },
        })
      })
    },
    getStepQuestions() {
      const id = localStorage.getItem('anonymous')
      this.$http.get(`api/questions/purchase/2/${id}?update=${this.$store.state.auth.updateRequest}&application_id=${this.$store.state.auth.applicationId}`).then(res => {
        this.questions = res.data
        this.loaded = true
        this.questions.questionOne.answers.forEach((a, index) => {
          if (a.selected) {
            this.selectedQ1Answer = index
          }
        })
        this.questions.questionTwo.answers.forEach((a, index) => {
          if (a.selected) {
            this.selectedQ2Answer = index
          }
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
